import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../Redux/Store';
import {
    AccountRole,
    CattleFinanceManagerRoleEnum,
    FeedlotManagerRoleEnum,
    IUser,
    ShippingTrackerRoleEnum,
    UserStatusEnum,
} from '../interfaces/UserManagementInterface';
import { usersApi } from '../../../../../../Redux/Apis/Users/usersApi';
import {
    accountsApi,
    getApiTmAccountsResponse,
} from '../../../../../../Redux/Apis/Accounts/accountsApi';
import { TenantManagementModelsUserManagementUserModelRead } from '../../../../../../Redux/Apis/Users/baseUsersApi';
import useAuthContext from '../../../../../common/modules/auth/hooks/useAuthContext';
import { deepClone, sortAlphaNumeric } from '../../../../../../helpers';

export const useGetQueryArgs = () => {
    const { t } = useTranslation();

    const { userManagementFilter } = useAppSelector(
        state => state.userManagement,
    );

    const getUserQueryString = () => {
        const filterConditions = [
            ...(userManagementFilter.name
                ? [
                      `contains(concat(concat(FirstName, ' '), LastName), '${userManagementFilter.name}')`,
                  ]
                : []),
            ...(userManagementFilter.email
                ? [`contains(Username, '${userManagementFilter.email}')`]
                : []),
            ...(+userManagementFilter.accountId
                ? [
                      `(Accounts/any(account:account/AccountId eq ${userManagementFilter.accountId}) or Accounts/any() eq false)`,
                  ]
                : []),
            ...(userManagementFilter.app &&
            userManagementFilter.app !==
                t('userManagement:userManagementLabels.staticOptions.selectApp')
                ? [
                      `Scopes/any(scope:scope/name eq 'APP:${userManagementFilter.app.replace(/\s/g, '')}')`,
                  ]
                : []),
            ...(userManagementFilter.status !== null
                ? [`Enabled eq ${userManagementFilter.status}`]
                : []),
        ];

        const filterClause = filterConditions.filter(Boolean).join(' and ');
        return filterClause || undefined;
    };

    const roleQuery =
        userManagementFilter.role &&
        userManagementFilter.role !==
            t('userManagement:userManagementLabels.staticOptions.selectRole')
            ? `${userManagementFilter.role.replace(/\s/g, '')}`
            : undefined;

    const queryArgs = {
        include: 'Roles,Scopes,Accounts.Roles',
        filter: getUserQueryString(),
        orderBy: 'FirstName,LastName',
        top: userManagementFilter.limit,
        skip: userManagementFilter.offset,
        role: roleQuery,
    };
    return queryArgs;
};

const filterApps = apps => {
    return apps.filter(app => app.name.startsWith('APP:'));
};

const formatAppName = (appName: string) => {
    return appName.replace('APP:', '').replace(/(?<!^)([A-Z])/g, ' $1');
};

export const spaceRoleName = (appName: string) => {
    const result = appName.replace(/(?<!^)([A-Z])/g, ' $1');
    return result;
};

const getGeneralRoles = (
    user: TenantManagementModelsUserManagementUserModelRead,
    validAppRoles,
) => {
    return (
        user.roles
            ?.filter(role =>
                validAppRoles.some(
                    appRole =>
                        role.name && appRole === spaceRoleName(role.name),
                ),
            )
            ?.map(role => spaceRoleName(role.name ?? '')) ?? []
    );
};

const getAccountRoles = (
    user: TenantManagementModelsUserManagementUserModelRead,
    validAppRoles,
    accessibleAccounts: getApiTmAccountsResponse[],
    selectedAccountId: number,
): AccountRole[] => {
    const accountsAndRoles: AccountRole[] = [];

    const filteredAccounts = user.accounts?.filter(account =>
        selectedAccountId
            ? account.accountId === selectedAccountId
            : accessibleAccounts.some(
                  accessibleAccount =>
                      accessibleAccount.accountId === account.accountId,
              ),
    );
    filteredAccounts?.forEach(account =>
        account?.roles
            ?.filter(role =>
                validAppRoles.some(
                    appRole =>
                        role.name && appRole === spaceRoleName(role.name),
                ),
            )
            ?.forEach(role => {
                accountsAndRoles.push({
                    account: account.account?.name,
                    role: role.name,
                });
            }),
    );
    return accountsAndRoles;
};

const handleAdminRoles = (generalRoles, isAdmin) => {
    if (generalRoles.includes('Super Admin')) {
        return false;
    } else if (isAdmin && !generalRoles.includes('Admin')) {
        return true;
    }
};

const handleFeedlotManager = (
    accountsAndRoles: AccountRole[],
    myAccountRoles,
    requireAllChangeable: boolean,
) => {
    let hasPermission = false;

    if (accountsAndRoles.length) {
        requireAllChangeable
            ? myAccountRoles.forEach(myRole => {
                  if (
                      accountsAndRoles.every(
                          accountRole =>
                              myRole === accountRole.account &&
                              accountRole.role !== 'FeedlotManager',
                      )
                  ) {
                      hasPermission = true;
                  }
              })
            : myAccountRoles.forEach(myRole => {
                  if (
                      accountsAndRoles.some(
                          accountRole =>
                              myRole === accountRole.account &&
                              accountRole.role !== 'FeedlotManager',
                      )
                  ) {
                      hasPermission = true;
                  }
              });
    }

    return hasPermission;
};

const useCanSetPassword = () => {
    const { isAdmin, myAccountRoles } =
        useGetDataForFilterDropdowns().editingPrivileges;

    const canSetPassword = (generalRoles, accountsAndRoles: AccountRole[]) => {
        return (
            handleAdminRoles(generalRoles, isAdmin) ??
            handleFeedlotManager(accountsAndRoles, myAccountRoles, true)
        );
    };

    return { canSetPassword };
};

const useIsEditable = () => {
    const { isAdmin, myAccountRoles } =
        useGetDataForFilterDropdowns().editingPrivileges;

    const isEditable = (generalRoles, accountsAndRoles: AccountRole[]) => {
        return (
            handleAdminRoles(generalRoles, isAdmin) ??
            handleFeedlotManager(accountsAndRoles, myAccountRoles, false)
        );
    };

    return { isEditable };
};

export const useGetUsers = ({
    apps,
    accessibleAccounts,
}: {
    apps: string[];
    accessibleAccounts: getApiTmAccountsResponse[];
}) => {
    const { userManagementFilter } = useAppSelector(
        state => state.userManagement,
    );

    const { data, isFetching } =
        usersApi.useGetApiTmUsersOdataQuery(useGetQueryArgs());

    const roleEnums = {
        'Cattle Finance Manager': CattleFinanceManagerRoleEnum,
        'Feedlot Manager': FeedlotManagerRoleEnum,
        'Shipping Tracker': ShippingTrackerRoleEnum,
    };

    const { canSetPassword } = useCanSetPassword();
    const { isEditable } = useIsEditable();

    const formatUserData = (): IUser[] => {
        if (data) {
            const validAppRoles = userManagementFilter.app
                ? Object.values(roleEnums[userManagementFilter.app] || [])
                : apps.map(app => Object.values(roleEnums[app] || [])).flat();

            const formattedUsers =
                data.map(user => {
                    const generalRoles = getGeneralRoles(user, validAppRoles);
                    const accountsAndRoles = getAccountRoles(
                        user,
                        validAppRoles,
                        accessibleAccounts,
                        +userManagementFilter.accountId,
                    );
                    const accountRoles = accountsAndRoles?.map(
                        accountRole =>
                            `${accountRole.account} - ${accountRole.role}`,
                    );
                    const roles = [...generalRoles, ...accountRoles];
                    const apps = filterApps(user.scopes).map(app => {
                        return formatAppName(app.name);
                    });
                    return {
                        userId: user.userId ?? 0,
                        name: user.fullName ?? '',
                        email: user.username ?? '',
                        apps,
                        roles,
                        status: user.enabled
                            ? UserStatusEnum.Active
                            : UserStatusEnum.Inactive,
                        resetPassword: canSetPassword(
                            generalRoles,
                            accountsAndRoles,
                        ),
                        editUser: isEditable(generalRoles, accountsAndRoles),
                    };
                }) ?? [];

            return formattedUsers;
        }
        return [];
    };

    return { users: formatUserData(), isFetching };
};

export const useGetDataForFilterDropdowns = () => {
    const { getUserId } = useAuthContext();

    const allTenantAccounts = accountsApi.useGetApiTmAccountsQuery({}).data;

    const myUserData = usersApi.useGetUserDataQuery({
        include: 'Roles,Scopes,Accounts.Roles',
        filter: `UserId eq ${getUserId()}`,
        top: 1,
    })?.data?.[0];

    const getApps = (): string[] => {
        return myUserData?.scopes
            ? sortAlphaNumeric(deepClone(myUserData)?.scopes, 'name')
                  ?.filter(scope => scope.name.includes('APP:'))
                  ?.map(scope => formatAppName(scope.name))
            : [];
    };

    const getAccounts = () => {
        if (myUserData?.accounts?.length) {
            return (
                allTenantAccounts?.filter(account =>
                    myUserData.accounts?.some(
                        myAccount => myAccount.accountId === account.accountId,
                    ),
                ) ?? []
            );
        } else {
            return allTenantAccounts || [];
        }
    };

    const getEditingPrivileges = () => {
        const myRoles = myUserData?.roles?.map(role => role.name) || [];
        const isAdmin = myRoles.includes('Admin');
        const myAccountRoles =
            deepClone(myUserData)
                ?.accounts?.filter(account =>
                    account.roles?.some(role => role.name === 'FeedlotManager'),
                )
                ?.map(account => account.account?.name) || [];
        return { isAdmin, myAccountRoles };
    };

    return {
        apps: getApps(),
        accounts: getAccounts(),
        editingPrivileges: getEditingPrivileges(),
    };
};

export const getRolesByApp = (app: string) => {
    const roleEnums = {
        'Select a Role': [],
        'Cattle Finance Manager': CattleFinanceManagerRoleEnum,
        'Feedlot Manager': FeedlotManagerRoleEnum,
        'Shipping Tracker': ShippingTrackerRoleEnum,
    };

    const validAppRoles: string[] =
        app !== 'Select an App' ? Object.values(roleEnums[app] || []) : [];
    const generalRolesRemoved = validAppRoles.filter(
        role => role !== 'Admin' && role !== 'User',
    );
    return generalRolesRemoved;
};
