export const UserManagementConstants = {
    apps: {
        feedlotManager: 'Feedlot Manager',
        shippingTracker: 'Shipping Tracker',
        cattleFinanceManager: 'Cattle Finance Manager',
    },
    statuses: [
        { enabled: true, label: 'Active' },
        { enabled: false, label: 'Inactive' },
    ],
};
