import { TenantManagementModelsUserManagementUserModelRead } from '../../../../../../Redux/Apis/Users/baseUsersApi';

export interface IUserManagementFilter {
    limit: number;
    offset: number;
    name: string;
    email: string;
    accountId: number;
    role: string;
    app: string;
    status: boolean | null;
}

export enum UserStatusEnum {
    Active = 'Active',
    Inactive = 'Inactive',
    All = 'All',
}

export interface AccountRole {
    account: string | null | undefined;
    role: string | null | undefined;
}

export enum CattleFinanceManagerRoleEnum {
    Admin = 'Admin',
    Finance = 'Finance',
    WarehousingApp = 'Warehousing App',
    User = 'User',
}

export enum FeedlotManagerRoleEnum {
    Admin = 'Admin',
    OfficeManager = 'Office Manager',
    FeedlotManager = 'Feedlot Manager',
    Cowboy = 'Cowboy',
    HealthManager = 'Health Manager',
    FeedTruckDriver = 'Feed Truck Driver',
    Operator = 'Operator',
    User = 'User',
}

export enum ShippingTrackerRoleEnum {
    Admin = 'Admin',
    Buyer = 'Buyer',
    OfficeManager = 'Office Manager',
    TruckingCompany = 'Trucking Company',
    User = 'User',
}

export interface IUser {
    userId: number;
    name: string;
    email: string;
    apps: string[];
    roles: string[];
    status: UserStatusEnum;
    resetPassword: boolean;
    editUser: boolean;
}

export interface IFeedlot {
    accountId: number;
    name: string;
}

export interface IRole {
    feedlot?: string;
    name: string;
}

export interface IUserManagementInitialState {
    userManagementFilter: IUserManagementFilter;
    users: IUser[];
    feedlots: IFeedlot[];
    roles: string[];
    apps: string[];
}

export interface ISelectStatus {
    name: string;
    isActive: boolean | null;
}

export interface IAdditionalRole {
    app: string;
    accountId: number;
    role: string;
}

export interface UserModelWithRequiredNames
    extends TenantManagementModelsUserManagementUserModelRead {
    firstName: string;
    lastName: string;
    username: string;
    enabled: boolean;
}
