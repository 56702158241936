import { TenantManagementModelsUserManagementRoleModel } from '../../../../../../Redux/Apis/Users/baseUsersApi';
import {
    CattleFinanceManagerRoleEnum,
    ShippingTrackerRoleEnum,
    UserModelWithRequiredNames,
} from '../interfaces/UserManagementInterface';
import { spaceRoleName } from '../hooks/userManagementHooks';
import DeleteIcon from '../../../../../../Assets/DeleteIcon';
import { Dispatch, SetStateAction } from 'react';

const UserGeneralRole = ({
    role,
    setUser,
    index,
}: {
    role: TenantManagementModelsUserManagementRoleModel;
    setUser: Dispatch<SetStateAction<UserModelWithRequiredNames | undefined>>;
    index: number;
}) => {
    const cattleFinanceManagerRoles: string[] = Object.values(
        CattleFinanceManagerRoleEnum,
    );
    const shippingTrackerRoles: string[] = Object.values(
        ShippingTrackerRoleEnum,
    );

    const app: string =
        role.name === 'Admin'
            ? '--'
            : cattleFinanceManagerRoles.includes(spaceRoleName(role.name ?? ''))
              ? 'Cattle Finance Manager'
              : shippingTrackerRoles.includes(spaceRoleName(role.name ?? ''))
                ? 'Shipping Tracker'
                : '';

    const deleteRole = () => {
        setUser(prevState => {
            if (!prevState) return prevState;
            const updatedRoles =
                prevState.roles?.filter((_, i) => i !== index) || [];
            return {
                ...prevState,
                roles: updatedRoles,
            };
        });
    };

    return role.name !== 'User' ? (
        <>
            <tr className="row flex-row d-flex mx-0">
                <td className="col-md-3">{app}</td>
                <td className="col-md-3">{'--'}</td>
                <td className="col-md-3">{spaceRoleName(role.name ?? '')}</td>
                <td className={`col-md-3 cursor-pointer text-center`}>
                    <DeleteIcon onClick={deleteRole} />
                </td>
            </tr>
        </>
    ) : null;
};

export default UserGeneralRole;
